<!-- @format -->

<div
	*ngIf="snackbarList.length"
	class="fixed left-1/2 -translate-x-1/2 md:translate-x-0 md:left-[unset] md:right-4 top-4 z-30 flex w-screen max-w-[428px] flex-col items-center justify-center space-y-2 px-2"
>
  <ng-container *ngFor="let snack of snackbarList">
    <div
      class="snackbar-shadow flex w-full items-center space-x-3 rounded-lg p-4"
      [ngClass]="bgClass[icon]"
      [@snackAnimation]
      *ngIf="snack.options?.icon as icon" [ngSwitch]="icon"
    >
        <i
          class="text-success-600"
          *ngSwitchCase="'done-2'"
          appSvgIcon
          [appIcon]="icon"
          appSquare="24"
        ></i>
        <i
          class="text-error"
          *ngSwitchCase="'error'"
          appSvgIcon
          [appIcon]="icon"
          appSquare="24"
        ></i>
      <span class="text-caption-lg-medium text-neutral-3000">
			{{ snack.message }}
		</span>
    </div>
  </ng-container>
</div>
