/** @format */

import { Inject, Injectable, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { APP_CONFIG } from './configuration.service';
import { PlatformService } from './platform.service';
import { Configuration } from '../models';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

@Injectable()
export class CookieService {
  constructor(
    @Optional() @Inject(REQUEST) private request: Request<any>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private configuration: Configuration,
    private platformService: PlatformService
  ) {}

  getItem(key: string): any {
    // prettier-ignore
    // @ts-ignore
    const cookie: string = this.platformService.isBrowser() ? this.document.cookie : this.request?.headers.cookie;

    // prettier-ignore
    const matches = cookie?.match(new RegExp('(?:^|; )' + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setItem(key: string, value: string, options?: any): void {
    if (this.platformService.isBrowser()) {
      options = {
        path: '/',
        ...options
      };

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
      }

      let updatedCookie = encodeURIComponent(key) + '=' + encodeURIComponent(value);

      // tslint:disable-next-line:forin
      for (const optionKey in options) {
        updatedCookie += '; ' + optionKey;

        const optionValue = options[optionKey];

        if (optionValue !== true) {
          updatedCookie += '=' + optionValue;
        }
      }

      this.document.cookie = updatedCookie;
    }
  }

  removeItem(item: string): void {
    if (this.platformService.isBrowser()) {
      this.setItem(item, '', {
        ['max-age']: -1
      });
    }
  }
}
