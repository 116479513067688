/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnInit {
  @Input() appUser!: User;

  @Input() appClassList: string = 'w-6 h-6 bg-neutral-100 text-neutral-400';

  @Input() appSquare: number = 12;

  constructor() {}

  ngOnInit(): void {}
}
