/** @format */

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from '../models';
import { SnackbarService } from './snackbar.service';
import { Router } from '@angular/router';
import { CookieService } from './cookie.service';
import { APP_CONFIG } from './configuration.service';

@Injectable()
export class ApiService {
  constructor(
    @Inject(APP_CONFIG) private configuration: Configuration,
    private http: HttpClient,
    private router: Router,
    private snackbarService: SnackbarService,
    private cookieService: CookieService
  ) {}

  handleErrors(response: any): Observable<any> {
    switch (true) {
      case !!response?.error && response?.error.hasOwnProperty('errors'): {
        const errors = response.error.errors;

        for (const error of Object.keys(errors)) {
          this.snackbarService.show(errors[error].shift(), { icon: 'error' });
        }

        break;
      }
      case !!response?.error && response?.error.hasOwnProperty('message'): {
        // prettier-ignore
        const message = response.error.message || 'Неизвестная ошибка';

        this.snackbarService.show(message, { icon: 'error' });

        break;
      }
      default: {
        this.snackbarService.show(response.statusText, { icon: 'error' });
      }
    }

    if ([401, 403].includes(response.status)) {
      this.cookieService.removeItem('tokenStoryPortUser');

      this.router.navigate(['/']).then(() => console.debug('Route changed'));
    }

    return throwError(() => response);
  }

  get(path: string, params: HttpParams = new HttpParams(), options?: any): Observable<any> {
    return this.http
      .get(this.configuration.apiDomain + path, { params, ...options })
      .pipe(catchError(error => this.handleErrors(error)));
  }

  put(path: string, body: any): Observable<any> {
    return this.http
      .put(this.configuration.apiDomain + path, body)
      .pipe(catchError(error => this.handleErrors(error)));
  }

  post(path: string, body?: any): Observable<any> {
    return this.http
      .post(this.configuration.apiDomain + path, body)
      .pipe(catchError(error => this.handleErrors(error)));
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete(this.configuration.apiDomain + path)
      .pipe(catchError(error => this.handleErrors(error)));
  }
}
