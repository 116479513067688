<!-- @format -->

<div
	class=" w-full fixed bottom-0 z-10"
	*ngIf="cookieToggle"
>
	<div class="flex bg-neutral-50 max-w-[1920px] items-center justify-between gap-4 space-x-1 mx-auto rounded-t-lg p-4">
    <div class="flex flex-row flex-wrap items-center justify-start gap-2">
      <p class="text-caption-sm-medium text-neutral-3000">
        Мы используем куки-файлы для более быстрого и удобного использования сайта
      </p>
      <a
        class="text-caption-sm-medium text-pink-500"
        href="/assets/docs/user_agreement.docx"
        download
      >
        Узнайте больше
      </a>
    </div>
    <button class="btn btn-primary btn-sm btn-inline" (click)="onAcceptCookies()">
      <span class="block">Ок, принять</span>
    </button>
  </div>
</div>
