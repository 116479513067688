/** @format */

import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SnackbarService } from './snackbar.service';
import { PlatformService } from './platform.service';

@Injectable()
export class HelperService {
  constructor(private snackbarService: SnackbarService, private platformService: PlatformService) {}

  getPlural(list: string[], n: number, round: 'ceil' | 'floor' = 'floor'): string {
    // List meaning words for 1, 2, 5 items

    let index = Math[round](Math.abs(n)) % 100;

    if (index >= 5 && index <= 20) {
      return list[2];
    }

    index %= 10;

    if (index === 1) {
      return list[0];
    }

    if (index >= 2 && index <= 4) {
      return list[1];
    }

    return list[2];
  }

  getRandomInt(max: number = 10, min: number = 0): number {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
  }

  getCustomValidation(customValidation: string, payload?: any): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors | null => {
      switch (customValidation) {
        case 'isSame': {
          const { left, right }: { left: string; right: string } = payload;

          const formGroup: FormGroup = abstractControl as FormGroup;

          if (formGroup.get(left)?.value !== formGroup.get(right)?.value) {
            return { isSame: false };
          }

          return null;
        }
        case 'isPassword': {
          const regex: RegExp = new RegExp('^(?=.{6,60}$)[a-zA-Z0-9\\W](?=.*[A-Z0-9\\W])');

          if (!regex.test(abstractControl.value)) {
            return { isPassword: true };
          }

          return null;
        }
        default: {
          return null;
        }
      }
    };
  }

  getFormValidation(form: any): boolean {
    if (form.valid) {
      return true;
    }

    Object.keys(form.controls).forEach(control =>
      form.get(control).markAsTouched({ onlySelf: true })
    );

    return false;
  }

  getFileValidation(file: File, mimeTypes: string[], size: number = 10): boolean {
    const maxSize = 1000 * 1000 * size;

    const isValidMime = mimeTypes.includes(file.type);
    const isValidSize = file.size <= maxSize;

    if (!isValidMime) {
      this.snackbarService.show('Выбранный вами файл не поддерживается', { icon: 'error' });
    }

    if (!isValidSize) {
      this.snackbarService.show(`Максимально допустимый размер файла до ${size} МБ`, {
        icon: 'error'
      });
    }

    return isValidMime && isValidSize;
  }

  isLessScreenMD(): boolean {
    if (this.platformService.isBrowser()) {
      const window: Window = this.platformService.getWindow();

      return window.innerWidth >= 767;
    }

    return false;
  }
}
