/** @format */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PlatformService, SnackbarService, User, UserService } from '../../../core';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  routerEvents$!: Subscription;

  user?: User;
  user$?: Subscription;
  currentYear: number = new Date().getFullYear();

  isHideOnMobile: boolean = false;
  hideOnMobilePageList: string[] = ['/settings', '/password', '/help'];

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    private platformService: PlatformService,
    private userService: UserService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe((user: User) => (this.user = user));

    this.isHideOnMobile = this.hideOnMobilePageList.includes(this.router.url);
    this.routerEvents$ = this.router.events
      // @ts-ignore
      .pipe(filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd))
      .subscribe((routerEvent: RouterEvent) => {
        this.isHideOnMobile = this.hideOnMobilePageList.includes(routerEvent.url);
      });
  }

  ngOnDestroy(): void {
    [this.user$, this.routerEvents$].forEach($ => $?.unsubscribe());
  }

  onCopy(text: string): void {
    if (this.user?.id) {
      this.router.navigate(['/help']).then(() => console.debug('Route changed'));
    } else {
      if (this.platformService.isBrowser()) {
        const window: Window = this.platformService.getWindow();

        this.snackbarService.show('Почта скопирована в буфер обмена', { icon: 'done-2' });

        if (!window.navigator.clipboard) {
          const textArea: HTMLTextAreaElement = this.document.createElement('textarea');
          textArea.value = text;

          // Avoid scrolling to bottom

          textArea.style.top = '0';
          textArea.style.left = '0';
          textArea.style.position = 'fixed';

          this.document.body.appendChild(textArea);

          textArea.focus();
          textArea.select();

          this.document.execCommand('copy');
          this.document.body.removeChild(textArea);

          return;
        }

        window.navigator.clipboard.writeText(text).then(() => console.debug('writeText'));
      }
    }
  }
}
